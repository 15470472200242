<template>
  <div class="side-padding" style="padding-top: 10vh; text-align: center;">
    <p>Tippelaget.no er på pause. Vi får se om vi kommer tilbake.</p>
  </div>
  <!-- <div class="intro-wrapper">
    <h1>Har du tippet feil?</h1>

    <p>
      Du har i alle fall havnet på en side som ikke finnes. Bruk navigasjonen på bunnen av siden eller oppe til høyre
      for å komme deg videre.
    </p>

    <p>
      Det går også an å
      <a href="https://www.facebook.com/tippelaget.no">kontakte oss på Facebook</a>
    </p>
  </div> -->
</template>

<style lang="scss" scoped>
@import "./src/SASS/settings";
@import "./src/SASS/modules/intro";
</style>
